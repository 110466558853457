import {
  InputLabel, TextField, makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  React,
} from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 24,
    marginRight: 24,
    paddingTop: 16,
  },
  comment: {
    // backgroundColor: theme.palette.background.paper,
    width: '100%',
    '& .MuiFilledInput-multiline': {
      paddingTop: 16,
      paddingLeft: 16,
    },
  },
  inputComments: {
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.primary.text,
  },
  helperText: {
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'right',
    marginTop: '-3px',
    paddingTop: '8px',
  },
  inputLabelComments: {
    paddingBottom: '16px',
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: '0',
    color: theme.palette.primary.text,
  },
  labelDescription: {
    fontFamily: theme.typography.h5.fontFamily,
    color: theme.palette.primary.text,
    fontSize: 14,
    paddingBottom: 16,
  },
}));

export default function CustomTextArea({
  title, placeHolder, size, values, setValues, description, screenId, isComment, rows, disableSize,
}) {
  const handleChange = (event) => {
    setValues(event.target.value);
  };

  const classes = useStyles();

  return (
    <div className={isComment ? classes.root : classes.default}>
      {title && <InputLabel htmlFor="comments" className={classes.inputLabelComments}>{title}</InputLabel>}
      {description && <InputLabel className={classes.labelDescription}>{description}</InputLabel>}
      <TextField
        id={`${screenId}`}
        inputProps={{
          maxLength: disableSize ? undefined : size,
          className: classes.inputComments,
        }}
        FormHelperTextProps={{
          align: 'right',
          classes: {
            root: classes.helperText,
          },
        }}
        multiline
        rows={rows}
        className={classes.comment}
        placeholder={placeHolder}
        variant="filled"
        color="secondary"
        value={values}
        onChange={handleChange}
        helperText={disableSize ? '' : `${values.length}/${size}`}
      />
    </div>
  );
}
CustomTextArea.propTypes = {
  title: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  values: PropTypes.string.isRequired,
  setValues: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  screenId: PropTypes.string.isRequired,
  isComment: PropTypes.bool.isRequired,
  rows: PropTypes.number.isRequired,
  disableSize: PropTypes.bool,
};

CustomTextArea.defaultProps = {
  disableSize: false,
};
