/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Grid, Link, TextField, makeStyles, InputLabel, RadioGroup, FormControlLabel, Radio, FormLabel,
  Button, MenuItem, Card, CardContent, Typography,
} from '@material-ui/core';
import {
  KeyboardDatePicker, MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { React, useState, useEffect } from 'react';
import { Alert } from '@material-ui/lab';
import DateUtils from '@date-io/moment';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { v4 as uuidv4 } from 'uuid';
import SearchButton from './SearchButton';
import DateRangePicker from './DateRangePicker';

import { ReactComponent as CalendarIcon } from '../../assets/ic_calendar.svg';

const useStyles = makeStyles((theme) => ({
  topContent: {
    padding: 24,
  },

  bottomContent: {
    paddingTop: 16,
    paddingBottom: 12,
  },
  topField: {
    minHeight: 76,
  },
  bottomLeftFields: {
    paddingLeft: 12,
  },
  radioGroup: {
    paddingLeft: 12,
    width: '100%',
    backgroundColor: 'white',
  },
  radioGrid: {
    marginTop: 16,
    marginRight: 8,
    height: 146,
    width: '100%',
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
  bottomRightFields: {
    paddingLeft: 12,
  },
  picker: {
    '& .MuiIconButton-root': {
      color: 'black',
    },
    width: '100%',
  },
  errorAlert: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 4,
  },
  bottomRightButton: {
    textAlign: 'right',
    alignSelf: 'flex-end',
    borderRadius: 12,
    marginRight: 12,
  },
  linkClass: {
    paddingTop: 15,
  },
  inputDescriptionLabel: {
    font: theme.typography.h4.font,
    fontSize: 13,
    paddingLeft: 12,
    paddingTop: 10,
    paddingBottom: 8,
  },
  radioButton: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
  },
  inputSubtitleLabel: {
    font: theme.typography.h4.font,
    color: theme.palette.primary.text,
  },
  JSONBuilder: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  filterButton: {
    backgroundColor: theme.palette.primary.ligthButton,
    color: theme.palette.common.black,
    margin: theme.spacing(1), // Adjust this value
    padding: theme.spacing(1),
    borderRadius: 12,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  filterItem: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '100%',
  },
  dateGrid: {
    width: '100%',
    paddingBottom: '16px',
  },
  filterField: {
    marginTop: '10px',
  },
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
  tabContent: {
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.tabContent,
    borderRadius: '12px',

  },
  accordionDetails: {
    width: '100%',
  },
  jsonTitle: {
    color: theme.palette.primary.black,
  },
  andItem: {
    paddingRight: '10px',
    fontWeight: 'bold',
    fontSize: '1.2em',
  },

}));

const operators = [
  { value: 'EQ', label: 'Is equal' },
  { value: 'NE', label: 'Is not equal' },
  { value: 'LT', label: 'Is lower than ' },
  { value: 'LE', label: 'Is lower or equal than' },
  { value: 'GT', label: 'Is greater than' },
  { value: 'GE', label: 'Is greater or equal than' },
  { value: 'IN', label: 'Is in' },
];

const booleanOperators = operators.filter((o) => o.value === 'EQ' || o.value === 'NE');

const stringOperators = operators.filter((o) => o.value === 'EQ' || o.value === 'NE' || o.value === 'IN');

const dateOperators = operators.filter((o) => o.value !== 'IN');

export const valueTypes = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  BOOLEAN: 'BOOLEAN',
  LIST: 'LIST',
};

export default function JSONBuilderFilters({
  setSelectedFilters,
  selectedStartDate, setSelectedStartDate, selectedStartTime,
  setSelectedStartTime, selectedEndDate, setSelectedEndDate,
  selectedEndTime, setSelectedEndTime, id,
  datesOptional, messageTypeVisible,
  filtersVisible, deviceSerialVisible,
  linkVisible, linkText, linkURL,
  deviceSerial, setDeviceSerial,
  messageType, setMessageType,
  deviceSerialError, maxDaysBetweenDates, subtitle,
  filtersComponents, jsonColumns, setDateRangePickerVisibility, setFilterError,
  filterError, onSearch,
}) {
  const classes = useStyles();

  /** * USESTATE SECTION */
  // array of filters that has been added (column, operator, value)
  const [filters, setFilters] = useState([]);
  // filters inputs we can see in the screen
  const [inputFilters, setInputFilters] = useState([
    {
      column: '', operator: '', value: '', id: 0,
    },
  ]);
  const [selectorError, setSelectorError] = useState('');
  const [columnsToSelect, setColumnsToSelect] = useState(jsonColumns);

  /** FUNCTIONS SECTION */
  const filterIsEmpty = (filter) => filter.column === '' || filter.operator === '' || filter.value === '';

  const addFilter = (filter) => {
    if (filterIsEmpty(filter)) {
      return;
    }
    // add the filter to the list of filters
    setFilters((prevFilters) => [...prevFilters || [], { ...filter }]);
    // add input filters
    setInputFilters((prevFilters) => [...prevFilters, {
      column: '', operator: '', value: '', id: uuidv4(),
    }]);
  };

  const deleteFilter = (filter) => {
    // delete the filter from the list of filters
    setFilters((prevFilters) => prevFilters.filter((f) => f.id !== filter.id));
    // delete the input filter
    setInputFilters((prevFilters) => prevFilters.filter((f) => f.id !== filter.id));
    // set selected to false in the columns to select
    setColumnsToSelect(
      columnsToSelect.map((column) => (column.id === filter.column
        ? { ...column, selected: false }
        : column)),
    );

    // if a mandatory filter has been deleted, it will be added automatically
    if (jsonColumns.find((c) => c.id === filter.column)?.isMandatory) {
      setInputFilters((prevFilters) => [...prevFilters, {
        column: filter.column, operator: '', value: '', id: uuidv4(), isMandatory: true, maxValues: jsonColumns.find((c) => c.id === filter.column)?.maxValues,
      }]);
      // set selected to false in the columns to select
      setColumnsToSelect(
        columnsToSelect.map((column) => (column.isMandatory
          ? { ...column, selected: true }
          : column)),
      );
    }

    // delete the filters that does not have column
    setFilters((prevFilters) => prevFilters.filter((f) => f.column !== ''));
    setInputFilters((prevFilters) => prevFilters.filter((f) => f.column !== ''));
  };

  const updateFilter = (field, filterValue, inputFilter) => {
    if (inputFilter.maxValues && field === 'value' && filterValue.split(',').length > inputFilter.maxValues) {
      setSelectorError(`You can only select up to ${inputFilter.maxValues} values`);
    } else setSelectorError('');
    setInputFilters(
      // change the speficic inputfilters related with the filterIndex
      inputFilters.map((filter, index) => (index === inputFilters.indexOf(inputFilter)
        ? {
          ...filter,
          [field]: filterValue,
        }
        : filter)),
    );
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const input = (inputFilters[inputFilters.length - 1]?.column !== '' && inputFilters[inputFilters.length - 1]?.operator !== '' && inputFilters[inputFilters.length - 1]?.value !== '') ? {
      column: inputFilters[inputFilters.length - 1].column,
      operator: inputFilters[inputFilters.length - 1].operator,
      value: inputFilters[inputFilters.length - 1].value,
    } : undefined;

    const createJsonFromFilters = () => [...filters, input]
      .filter((filter) => filter)
      .map((filter) => ({
        column: filter.column,
        operator: filter.operator,
        value: filter.value,
      }));

    if (inputFilters[inputFilters.length - 1]?.column !== '' && inputFilters[inputFilters.length - 1]?.operator !== '' && inputFilters[inputFilters.length - 1]?.value !== '') {
      addFilter(inputFilters[inputFilters.length - 1]);
    }
    onSearch(event, JSON.stringify({ filter: createJsonFromFilters() }, null, 2));
  };

  /** USEEFFECT SECTION */
  // just to initialize the filters once, when the component is mounted, with the mandatory ones
  useEffect(() => {
    const initialFilters = jsonColumns.filter((col) => col.isMandatory).map((col) => ({
      id: uuidv4(),
      column: col.id,
      operator: operators[0].value,
      value: '',
      isMandatory: col.isMandatory,
      maxValues: col.maxValues,
    }));
    if (initialFilters.length > 0) {
      setInputFilters(initialFilters);
      // deleted from the columns to select the mandatory columns
      setColumnsToSelect(
        columnsToSelect.map((column) => (column.isMandatory
          ? { ...column, selected: true }
          : column)),
      );
    }
  }, []);

  // validate mandatory filters has been added
  useEffect(() => {
    if (inputFilters.length > 0) {
      const mandatoryFilters = inputFilters.filter((filter) => filter.isMandatory);
      if (mandatoryFilters.length > 0) {
        if (mandatoryFilters.some((filter) => filter.column === '' || filter.operator === '' || filter.value === '')) {
          setFilterError('The mandatory filters must been added');
        } else {
          setFilterError('');
        }
      }
    }
    if (inputFilters.length === 0) {
      setInputFilters([
        {
          column: '', operator: '', value: '', id: uuidv4(),
        },
      ]);
    }
  }, [inputFilters, setFilterError]);

  /** EVENT FUNCTIONS SECTIONS */
  const handleDateChange = (startDate, endDate) => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  const handleColumnChange = (event, filterIndex) => {
    // if column is date, set the value in the filter to the date in the selectedStartDate
    if (jsonColumns.find((c) => c.id === event.target.value)?.valueType === valueTypes.DATE) {
      setInputFilters(
        // change the speficic inputfilters related with the filterIndex
        inputFilters.map((filter, index) => (index === filterIndex
          ? {
            ...filter,
            column: event.target.value,
            operator: operators[0].value,
            value: selectedStartDate.format('YYYY-MM-DD HH:mm:ss'),

          }
          : filter)),

      );
    } else {
      setInputFilters(
        // change the speficic inputfilters related with the filterIndex
        inputFilters.map((filter, index) => (index === filterIndex
          ? {
            ...filter,
            column: event.target.value,
            operator: operators[0].value,
            value: '',

          }
          : filter)),
      );
    }

    // add to the columns to select a new property call selected for the columns that has been selected
    setColumnsToSelect(
      columnsToSelect.map((column) => (column.id === event.target.value
        ? { ...column, selected: true }
        : column)),
    );
  };

  const handleChangeDatePicker = (value) => {
    if (value === null) {
      setSelectedStartDate(null);
    } else {
      setSelectedStartDate(value);
      setInputFilters(
        // change the speficic inputfilters related with the filterIndex
        inputFilters.map((filter, index) => (index === inputFilters.length - 1
          ? {
            ...filter,
            value: value.format('YYYY-MM-DD HH:mm:ss'),
          }
          : filter)),
      );
    }
  };

  return (

    <Grid
      item
      container
      spacing={0}
      direction="column"
      className={classes.topContent}
    >
      {subtitle !== '' && (
        <Grid>
          <InputLabel
            className={classes.inputSubtitleLabel}
          >
            {subtitle}
          </InputLabel>
        </Grid>
      )}
      {filtersComponents.map((component) => (
        component.type === 'radio' && (
          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" xs={2} className={classes.radioGrid}>
            <FormLabel className={classes.inputDescriptionLabel}>{component.label}</FormLabel>
            <RadioGroup
              className={classes.radioGroup}
              onChange={component.handleChange}
              defaultValue={component.defaultValue}
            >
              {component.values.map((value) => (
                <FormControlLabel
                  id={`btnRadio${id}${value.label}`}
                  className={classes.radioButton}
                  value={value.value}
                  control={<Radio />}
                  label={value.label}
                />
              ))}
            </RadioGroup>
          </Grid>
        )
      ))}
      <Grid
        item
        container
        direction="row"
        className={classes.bottomContent}
        spacing={1}
      >
        <Grid container xs={12} md={6}>
          <Grid item xs={12} className={classes.bottomLeftFields}>
            <DateRangePicker
              initialStartDate={new Date()}
              initialEndDate={new Date()}
              onDateChange={handleDateChange}
              setSelectedStartDate={setSelectedStartDate}
              selectedStartDate={selectedStartDate}
              setSelectedStartTime={setSelectedStartTime}
              selectedStartTime={selectedStartTime}
              setSelectedEndDate={setSelectedEndDate}
              selectedEndDate={selectedEndDate}
              setSelectedEndTime={setSelectedEndTime}
              selectedEndTime={selectedEndTime}
              datesOptional={datesOptional}
              maxDaysBetweenDates={maxDaysBetweenDates}
              setVisibility={setDateRangePickerVisibility}
            />
          </Grid>
        </Grid>

        {deviceSerialVisible && (
          <Grid item container justifyContent="center" xs={filtersVisible && filtersComponents.length > 0 ? 12 : 6} py={3}>
            <Grid item xs={12} className={classes.bottomLeftFields}>
              <TextField
                id={`input${id}DeviceSerial`}
                variant="filled"
                label="Device Serial"
                value={deviceSerial}
                onChange={(event) => {
                  setDeviceSerial(event.target.value);
                }}
                orientation="landscape"
                inputVariant="filled"
                className={classes.picker}
                color="secondary"
              />
              {deviceSerialError && <Alert severity="error" className={classes.errorAlert}>Cannot be empty</Alert>}
            </Grid>
          </Grid>
        )}
        {messageTypeVisible && (
          <Grid item container justifyContent="center" xs={filtersVisible && filtersComponents.length > 0 ? 12 : 6} py={3}>
            <Grid item xs={12} className={classes.bottomLeftFields}>
              <TextField
                id={`input${id}MessageType`}
                variant="filled"
                label="Message type"
                value={messageType}
                onChange={(event) => {
                  setMessageType(event.target.value);
                }}
                orientation="landscape"
                inputVariant="filled"
                className={classes.picker}
                color="secondary"
              />
            </Grid>
          </Grid>
        )}

        <div className={classes.JSONBuilder}>

          {filtersVisible && (

            <div className={classes.root}>

              <Card>
                <CardContent>
                  <Typography variant="body2" color="textSecondary" component="p">
                    JSON Filters (
                    {inputFilters?.length - 1}
                    {' '}
                    {inputFilters?.length - 1 === 1 ? 'filter' : 'filters'}
                    {' '}
                    applied)
                  </Typography>
                  <Grid container style={{ paddingLeft: '16px', paddingRight: '16px' }}>

                    <div className={classes.tabContent}>
                      {jsonColumns?.length > 0 && inputFilters?.length > 0 && (
                        inputFilters.map((inputFilter, filterIndex) => (
                          <Grid
                            key={inputFilter.id}
                            item
                            container
                            direction="row"
                            spacing={4}
                          >

                            {/* // input columns */}
                            <Grid item xs={12} sm={2}>
                              <TextField
                                className={classes.filterItem}
                                key={inputFilter.id}
                                fullWidth
                                disabled={(inputFilters?.length && filterIndex !== inputFilters.length - 1) || inputFilter.isMandatory}
                                select
                                label="Column"
                                value={inputFilter.column}
                                onChange={(event) => handleColumnChange(event, filterIndex)}
                                data-testid={`input-column-${inputFilter.id}`}
                              >

                                {columnsToSelect.map((c) => (
                                  (

                                    // set disable the menu item if the column has been selected
                                    <MenuItem key={c.id} value={c.id} data-testid={`input-column-${inputFilter.id}-${c.id}`} disabled={c.selected}>
                                      {c.label}
                                    </MenuItem>
                                  )

                                ))}

                              </TextField>
                            </Grid>

                            {/* // input operators */}
                            <Grid item xs={12} sm={2}>
                              <TextField
                                className={classes.filterItem}
                                key={inputFilter.id}
                                disabled={inputFilters?.length && filterIndex !== inputFilters.length - 1}
                                fullWidth
                                select
                                label="Operator"
                                value={inputFilter.operator}
                                onChange={(e) => updateFilter('operator', e.target.value, inputFilter)}
                                data-testid={`input-operator-${inputFilter.id}`}
                              >
                                {
                                  ![valueTypes.BOOLEAN, valueTypes.STRING].includes(jsonColumns.find((c) => c.id === inputFilter.column)?.valueType) && dateOperators.map((option) => (
                                    <MenuItem key={option.value} value={option.value} data-testid={`input-operator-${option.value}-${inputFilter.id}`}>
                                      {option.label}
                                    </MenuItem>
                                  ))
                                }

                                {
                                  [valueTypes.BOOLEAN].includes(jsonColumns.find((c) => c.id === inputFilter.column)?.valueType) && booleanOperators.map((option) => (
                                    <MenuItem key={option.value} value={option.value} data-testid={`input-operator-${option.value}-${inputFilter.id}`}>
                                      {option.label}
                                    </MenuItem>
                                  ))
                                }

                                {
                                  [valueTypes.STRING].includes(jsonColumns.find((c) => c.id === inputFilter.column)?.valueType) && stringOperators.map((option) => (
                                    <MenuItem key={option.value} value={option.value} data-testid={`input-operator-${option.value}-${inputFilter.id}`}>
                                      {option.label}
                                    </MenuItem>
                                  ))
                                }

                              </TextField>
                            </Grid>

                            {/* // input values */}
                            <Grid item xs={12} sm={2} md={4}>
                              {inputFilter.column
                                && jsonColumns.find((c) => c.id === inputFilter.column)?.valueType === (valueTypes.BOOLEAN || valueTypes.LIST)
                                && (
                                  <TextField
                                    className={classes.filterItem}
                                    key={inputFilter.id}
                                    disabled={inputFilters?.length && filterIndex !== inputFilters.length - 1}
                                    select
                                    label="Value"
                                    fullWidth
                                    value={inputFilter.value}
                                    onChange={(e) => updateFilter('value', e.target.value, inputFilter)}
                                    data-testid={`input-value-boolean-list-${inputFilter.id}`}
                                  >
                                    {jsonColumns.find((c) => c.id === inputFilter.column)?.values?.map((option) => (
                                      <MenuItem key={option} value={option} data-testid={option}>
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                              {inputFilter.column
                                && jsonColumns.find((c) => c.id === inputFilter.column)?.valueType === valueTypes.STRING
                                && (
                                  <TextField
                                    className={classes.filterItem}
                                    key={inputFilter.id}
                                    disabled={inputFilters?.length && filterIndex !== inputFilters.length - 1}
                                    fullWidth
                                    label={inputFilter.operator === 'IN' ? 'Values (separated by commas)' : 'Value'}
                                    value={inputFilter.value}
                                    onChange={(e) => updateFilter('value', e.target.value, inputFilter)}
                                    data-testid={`input-value-string-${inputFilter.id}`}
                                  />
                                )}
                              {inputFilter.column
                                && jsonColumns.find((c) => c.id === inputFilter.column)?.valueType === valueTypes.NUMBER
                                && (
                                  <TextField
                                    className={classes.filterItem}
                                    key={inputFilter.id}
                                    disabled={inputFilters?.length && filterIndex !== inputFilters.length - 1}
                                    fullWidth
                                    label={inputFilter.operator === 'IN' ? 'Values (enter the values separated by commas)' : 'Value'}
                                    inputProps={{
                                      pattern: '\\d*',
                                    }}
                                    value={inputFilter.value}
                                    onChange={(e) => updateFilter('value', e.target.value, inputFilter)}
                                    data-testid={`input-value-number-${inputFilter.id}`}
                                  />
                                )}
                              {
                                inputFilter.column
                                && jsonColumns.find((c) => c.id === inputFilter.column)?.valueType === valueTypes.DATE
                                && (

                                  <Grid item className={classes.dateGrid} container>
                                    <MuiPickersUtilsProvider utils={DateUtils}>
                                      <KeyboardDatePicker
                                        autoOk
                                        id={`input${id}Date`}
                                        key={inputFilter.id}
                                        disabled={inputFilters?.length && filterIndex !== inputFilters.length - 1}
                                        variant="outlined"
                                        orientation="landscape"
                                        value={selectedStartDate}
                                        format="yyyy-MM-DD HH:mm:ss"
                                        onChange={handleChangeDatePicker}
                                        color="secondary"
                                        data-testid={`input-value-calendar-${inputFilter.id}`}
                                        TextFieldComponent={({ value, onChange, onClick }) => (
                                          <TextField
                                            value={value}
                                            data-testid={`input-value-calendar-${inputFilter.id}`}
                                            disabled={inputFilters?.length && filterIndex !== inputFilters.length - 1}
                                            onChange={onChange}
                                            variant="outlined"
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <IconButton onClick={onClick}>
                                                    <CalendarIcon />
                                                  </IconButton>
                                                </InputAdornment>
                                              ),
                                            }}

                                          />
                                        )}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </Grid>

                                )
                              }
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} direction="row">
                              <Grid contaicner alignItems="center" justifyContent="center">
                                {/* show this only in the last element of the array */}
                                {filterIndex === inputFilters.length - 1 && (
                                  <Button variant="contained" color="secondary" onClick={() => addFilter(inputFilter)} startIcon={<AddIcon />} data-testid="add-filter-button" disabled={selectorError !== ''} className={classes.filterButton}> ADD </Button>)}

                                {/* show this in all elements except in the last one */}
                                {filterIndex !== inputFilters.length - 1 && (
                                  <Grid container justifyContent="center" alignContent="center" alignItems="center" display="flex">
                                    <Grid item className={classes.andItem}>
                                      AND
                                    </Grid>
                                    <Grid item>
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => deleteFilter(inputFilter)}
                                        startIcon={<DeleteIcon />}
                                        data-testid="delete-filter-button"
                                        className={classes.filterButton}
                                      >
                                        {' '}
                                        DELETE
                                      </Button>
                                    </Grid>

                                  </Grid>
                                )}
                              </Grid>
                            </Grid>

                          </Grid>
                        ))
                      )}
                    </div>

                  </Grid>
                </CardContent>
              </Card>

            </div>

          )}
        </div>

        <Grid item container justifyContent={linkVisible ? 'space-between' : 'flex-end'} xs={12} className={classes.bottomRightButton}>
          {linkVisible && (
            <Link
              href={linkURL}
              underline="always"
              target="_blank"
              className={classes.linkClass}
            >
              {linkText}
            </Link>
          )}
          <SearchButton
            id={`btn${id}Search`}
            onClick={onSubmit}
            className={classes.bottomRightButton}
            disabled={filterError !== ''}
          >
            SEARCH
          </SearchButton>

        </Grid>

      </Grid>

    </Grid>

  );
}
JSONBuilderFilters.propTypes = {
  setSelectedFilters: PropTypes.func.isRequired,
  selectedStartDate: PropTypes.string.isRequired,
  setSelectedStartDate: PropTypes.func.isRequired,
  selectedStartTime: PropTypes.string.isRequired,
  setSelectedStartTime: PropTypes.func.isRequired,
  selectedEndDate: PropTypes.string.isRequired,
  setSelectedEndDate: PropTypes.func.isRequired,
  selectedEndTime: PropTypes.string.isRequired,
  setSelectedEndTime: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  filtersVisible: PropTypes.bool.isRequired,
  deviceSerialVisible: PropTypes.bool.isRequired,
  linkVisible: PropTypes.bool,
  linkText: PropTypes.string,
  linkURL: PropTypes.string,
  messageTypeVisible: PropTypes.bool,
  datesOptional: PropTypes.bool,
  deviceSerial: PropTypes.string,
  setDeviceSerial: PropTypes.func,
  messageType: PropTypes.string,
  setMessageType: PropTypes.func,
  deviceSerialError: PropTypes.bool,
  maxDaysBetweenDates: PropTypes.number,
  subtitle: PropTypes.string,
  filtersComponents: PropTypes.arrayOf(PropTypes.object),
  jsonColumns: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  setDateRangePickerVisibility: PropTypes.bool,
  setFilterError: PropTypes.func,
  filterError: PropTypes.string,
  onSearch: PropTypes.func,
};

JSONBuilderFilters.defaultProps = {
  linkVisible: false,
  linkText: '',
  linkURL: '',
  messageTypeVisible: false,
  datesOptional: true,
  deviceSerial: '',
  setDeviceSerial: () => { },
  messageType: '',
  setMessageType: () => { },
  deviceSerialError: false,
  maxDaysBetweenDates: 30,
  subtitle: '',
  filtersComponents: [],
  jsonColumns: [],
  setDateRangePickerVisibility: false,
  setFilterError: () => { },
  filterError: '',
  onSearch: () => { },
};
