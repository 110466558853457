/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  React, useState, useEffect, useRef,
} from 'react';
import {
  MenuItem,
  withStyles,
  Box,
  Button,
  Menu,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { useProject } from '../../config/ProjectContext';

const ModelPickerArrowDown = withStyles((theme) => ({
  root: {
    color: theme.palette.common.black,
    marginRight: 12,
  },
}))(ArrowDropDownIcon);

const ModelPickerArrowUp = withStyles((theme) => ({
  root: {
    color: theme.palette.common.black,
    marginRight: 12,
  },
}))(ArrowDropUpIcon);

export default function ProjectPicker({ projects }) {
  const useStyles = makeStyles((theme) => ({
    pickerContainer: {

      fullWidth: true,
      height: 40,
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 8,
      paddingBottom: 8,
      borderRadius: 12,
      color: theme.palette.common.black,
      backgroundColor: theme.palette.background.form,

      '&.MuiButton-root:hover': {
        backgroundColor: theme.palette.background.banner,
      },
      '&.MuiButton-root.Mui-disabled': {
        color: 'gray',
      },
      '& .MuiButton-label': {
        justifyContent: 'space-between',
        paddingLeft: 8,
      },
    },
    menu: {
      paddingRight: 0,
      '&.MuiButton-root:hover': {
        backgroundColor: theme.palette.primary.banner,
      },
    },
    menuList: {
      '& .MuiMenu-paper': {
        borderRadius: 12,
        borderColor: theme.palette.primary.subMain,
        border: 'solid',
        borderWidth: 1,
      },
      '& .MuiMenu-list': {
        paddingTop: 0,
        paddingBottom: 0,
        minWidth: 474,
      },
    },
    menuList2: {
      '& .MuiMenu-paper': {
        borderRadius: 12,
        borderColor: theme.palette.primary.subMain,
        border: 'solid',
        borderWidth: 1,
        marginLeft: 4,
        marginTop: -10,
      },
      '& .MuiMenu-list': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    menuItem: {
      justifyContent: 'space-between',
      height: 32,
      '&.MuiListItem-button:hover': {
        backgroundColor: theme.palette.secondary.subMain,
        color: theme.palette.primary.main,

      },
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 10,
      marginBottom: 10,
      marginLeft: 4,
      marginRight: 4,
      borderRadius: 8,
    },
    menuItemText: {
      display: 'inline-flex',
      verticalAlign: 'middle',
      paddingLeft: 18,
      paddingRight: 12,
    },
    menuItemArrow: {
      height: 14,
      width: 14,
    },
    mainBox: {
      display: 'flex',
      width: '100%',
    },
    form: {
      display: 'flex',
      width: '100%',
    },
  }));

  // get the project from local storage
  const { project, setProject } = useProject();
  const [menuPosition, setMenuPosition] = useState(null);
  const inputRef = useRef();

  const {
    control,
  } = useForm({
  });

  const classes = useStyles();

  const handleButtonClick = (event, parent) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: parent?.current?.offsetHeight + parent?.current?.offsetTop || event.pageY,
    });
  };

  const handleItemClick = (item) => {
    setProject(item);
    localStorage.setItem('project', JSON.stringify(item));
    setMenuPosition(null);
  };

  useEffect(() => {
    if (projects) {
      const selectedProject = projects?.find((item) => item?.id === project?.id);
      setProject(selectedProject || projects[0]);
    }
  }, [projects, project.id, setProject]);

  const isDisabled = !projects || projects.length === 0;

  return (
    <form className={classes.form} id="project-picker-form">
      <Box className={classes.mainBox}>
        <Button
          onClick={(evt) => handleButtonClick(evt, inputRef)}
          endIcon={!menuPosition ? <ModelPickerArrowDown /> : <ModelPickerArrowUp />}
          className={classes.pickerContainer}
          disabled={isDisabled}
          ref={inputRef}
          id="project-picker-button"
          fullWidth
        >
          {project?.name || 'No projects retrieved'}
        </Button>
        <Menu
          open={!!menuPosition}
          onClose={() => setMenuPosition(null)}
          // anchorReference="anchorPosition"
          // anchorPosition={menuPosition}
          className={[classes.menu, classes.menuList]}
          elevation={0}
          anchorEl={inputRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          id="project-picker-menu"
        >

          {projects && projects?.map((item) => (
            <MenuItem
              key={item.id}
              value={item}
              className={classes.menuItem}
              onClick={() => handleItemClick(item)}
            >
              <div className={classes.menuItemText}>
                {item.name}
              </div>
            </MenuItem>
          ))}

        </Menu>
      </Box>
    </form>
  );
}
ProjectPicker.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
};
