/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box, Grid, LinearProgress, makeStyles, Checkbox, ListItemText,
} from '@material-ui/core';
import {
  React, useContext, useState, useEffect,
} from 'react';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import PageBanner from '../../components/utils/PageBanner';
// Local imports
import { useProject } from '../../config/ProjectContext';
import SelectedAssetsContext from '../../config/SelectedAssetsContext';
import AssetAccordion from '../../components/AssetList/AssetAccordion';
import GraphqlService from '../../service/graphqlService';
import YesNoDialog from '../../components/utils/YesNoDialog';
import {
  CREATE_NON_GLOBAL_DEPLOY_PERMISSION,
} from '../../utils/constants';
import { ReactComponent as UploadLogo } from '../../assets/ic_file_upload.svg';
import MessageCard from '../../components/utils/MessageCard';
import { ReactComponent as DeleteIcon } from '../../assets/ic_bin_white.svg';
import ProgressDialog from '../../components/utils/ProgressDialog';
import PaginationComponent from '../../components/Pagination';
import { useSnackbar } from '../../providers/SnackbarContext';

export default function AssetList() {
  const useStyles = makeStyles((theme) => ({
    separate: {
      flexWrap: 'noWrap',
    },
    pagination: {
      background: theme.palette.background.paper,
      borderRadius: 12,
      boxShadow: theme.customShadows.regularShadow,
      color: theme.palette.primary.text,
      float: 'right',
      padding: 0,
      width: '100%',
    },
    background: {
      backgroundColor: theme.palette.background.typography,
      padding: 16,
    },
    button: {
      float: 'right',
      margin: '0 0 16px 24px',
    },
    deployButton: {
      float: 'left',
      margin: '0 0 16px 24px',
    },
    pageSizeTitle: {
      fontSize: 14,
      paddingRight: '24px',
    },
    paginationRoot: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      padding: theme.spacing(2),
    },
    selectPaginationSize: {
      marginRight: '8px',
      borderRadius: 12,
    },
    paginationSizeLabel: {
      paddingRight: '12px',
      fontSize: 16,
    },
    tableIcons: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingBottom: 16,
      flexWrap: 'wrap',
    },
    tableButton: {
      width: '100%',
      height: 48,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    refreshButton: {
      height: 48,
      justifyContent: 'center',
      margin: 4,
      color: 'white',
      borderRadius: 12,
    },
    assetTypeFilterStyle: {
      minWidth: 210,
    },
    orderDateStyle: {
      minWidth: 130,
    },
  }));

  // STATE________________________________

  //  state values;
  const { project } = useProject();

  const [yesNoDialogOpen, setYesNoDialogOpen] = useState(false);
  const [yesNoDialogText, setYesNoDialogText] = useState('');
  const { selectedAssets, setSelectedAssets } = useContext(SelectedAssetsContext);
  const [sortDirection, setSortDirection] = useState('DESC');
  const [selectedAssetTypes, setSelectedAssetTypes] = useState([]);
  const [assetTypeFilter, setAssetTypeFilter] = useState();

  const [lastAssetTypeFilter, setLastAssetTypeFilter] = useState();
  const [openAssetTypeFilter, setOpenAssetTypeFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const history = useHistory();
  const match = useRouteMatch();
  const { fileId: fileIdParam } = useParams();
  const [lastProjectCode, setLastProjectCode] = useState(null);

  const openSnackBar = useSnackbar();

  const getType = assetTypeFilter === null ? null : assetTypeFilter;

  const fetchAssets = async () => {
    if (project?.code !== lastProjectCode) {
      setCurrentPage(0);
      setLastProjectCode(project?.code);
      setSelectedAssetTypes([]);
      setAssetTypeFilter(null);
      setSelectedAssets([]);
    } else if (assetTypeFilter !== lastAssetTypeFilter) {
      setCurrentPage(0);
      setLastAssetTypeFilter(assetTypeFilter);
      setSelectedAssets([]);
    }

    return GraphqlService.listAssetsNotDeleted({
      projectId: project?.code,
      page: currentPage,
      pageSize,
      sortDirection,
      type: getType,
    });
  };

  const getAsset = async () => GraphqlService.getAsset({
    projectId: project?.code,
    fileId: fileIdParam,
  });

  const {
    data: assetFromParams,
    error: errorFetchingAsset,
    isLoading: isLoadingAsset,
  } = useQuery(['asset', fileIdParam], () => getAsset(), {
    enabled: !!fileIdParam && !!project?.code,
    cacheTime: 0,
    select: (res) => {
      try {
        const parsedDescription = res.data.assetDescription ? JSON.parse(res.data.assetDescription) : {};
        return { ...res.data, assetDescription: parsedDescription };
      } catch (error) {
        return res.data;
      }
    },
  });

  const {
    data: assets,
    error: errorFetchingAssets,
    refetch: refetchAssets,
    isLoading,
  } = useQuery(['assets', project?.code, project?.permission, currentPage, pageSize, sortDirection, assetTypeFilter], () => fetchAssets({
    projectId: project?.code,
    page: currentPage,
    pageSize,
    sortDirection,
    type: getType,
  }), {
    enabled: !!project && !!project?.code && !fileIdParam,
    cacheTime: 0,
    select: (assetsResponse) => {
      const transformedAssets = assetsResponse.data.map((asset) => {
        try {
          const parsedDescription = asset.assetDescription ? JSON.parse(asset.assetDescription) : {};
          return { ...asset, assetDescription: parsedDescription };
        } catch (error) {
          return asset;
        }
      });
      return { ...assetsResponse, data: transformedAssets };
    },
  });

  const fetchAssetTypes = async () => GraphqlService.getAssetTypes({
    projectId: project?.code,
  });

  const {
    data: assetTypes,
    error: errorFetchingAssetTypes,
    isLoading: isLoadingAssetTypes,
  } = useQuery(['assetTypes', project?.code, project?.permission, currentPage, pageSize, sortDirection], () => fetchAssetTypes({
    projectId: project?.code,
  }), {
    enabled: !!project,
    cacheTime: 0,
  });

  useEffect(() => {
    if (errorFetchingAssetTypes) {
      openSnackBar('Error fetching Asset Types', 'error');
    }
  }, [errorFetchingAssetTypes]);

  // fetch the assets when loading the page
  useEffect(() => {
    if (project?.code) {
      refetchAssets();
    }
  }, []);

  const classes = useStyles();

  const queryClient = useQueryClient();

  const deleteAssetsMutation = useMutation(
    (deleteAssetInput) => GraphqlService.deleteAsset(project.code, deleteAssetInput),
    {
      onSuccess: (responsesArray) => {
        const showPopup = responsesArray.find((r) => r.statusCode !== null);
        if (showPopup) {
          const messages = [];
          responsesArray.map((r) => {
            messages.push(r.message);
            return 0;
          });
          openSnackBar(messages.join('\n'), 'error');
        } else {
          openSnackBar('Assets deleted!', 'success');
          setSelectedAssets([]);
          queryClient.invalidateQueries('assets');
        }
      },
      onError: (error) => {
        openSnackBar(`Error deleting asset: ${error.message}`, 'error');
      },
    },
  );

  const onClickNewAsset = () => {
    history.push(`${match.url}/create`);
  };

  const onClickNewDeployment = () => {
    if (selectedAssets.length <= 0) return;
    history.push(`${match.url}/deployments/create`);
  };

  const onClickDeleteAssets = () => {
    setYesNoDialogText(`You are about to delete ${selectedAssets.length} assets. Are you sure?`);
    setYesNoDialogOpen(true);
  };

  const confirmDeleteAssets = () => {
    const deleteAssetInput = selectedAssets.map((asset) => ({
      fileId: asset.fileID,
      chunks: asset.chunks,
    }));

    deleteAssetsMutation.mutate(deleteAssetInput);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1);
  };

  const handleSelectChange = (event) => {
    setPageSize(event.target.value);
  };

  const handleOrderByChange = (event) => {
    setSortDirection(event.target.value);
  };

  const handleAssetTypeFilterChange = (event) => {
    const { value } = event.target;
    // Need to handle the selection as an array
    setSelectedAssetTypes(value);
    // transform the array in a string with values sepparated by commas
    if (!value) {
      setAssetTypeFilter(null);
    } else setAssetTypeFilter(value.join(',').toString());
  };

  const handleClose = () => {
    setOpenAssetTypeFilter(false);
  };

  const handleOpenAssetTypeFilter = () => {
    setOpenAssetTypeFilter(true);
  };

  function renderContent() {
    if (errorFetchingAssets) {
      return <MessageCard message={`Error: ${errorFetchingAssets.message}`} />;
    }

    if (errorFetchingAsset) {
      return <MessageCard message={`Error: ${errorFetchingAsset.message}`} />;
    }

    if (((isLoading || !project?.code) && !fileIdParam) || ((isLoadingAsset || !project?.code) && !!fileIdParam)) {
      return (
        <>
          <ProgressDialog open header="Retrieving assets, please wait" />
          <LinearProgress id="linear-progress-id" style={{ width: '100%' }} />
        </>
      );
    }

    if (assetFromParams) {
      return (
        <AssetAccordion
          asset={assetFromParams}
          expandedProp={!!assetFromParams}
        />
      );
    }

    return (
      <>
        <div className={classes.tableIcons}>

          {
            assetTypes?.data?.length > 0 && !isLoadingAssetTypes && (

              <FormControl variant="filled" className={classes.assetTypeFilterStyle}>
                <InputLabel>Asset Type Filter</InputLabel>
                <Select
                  multiple
                  open={openAssetTypeFilter}
                  onOpen={handleOpenAssetTypeFilter}
                  onClose={handleClose}
                  value={selectedAssetTypes}
                  onChange={handleAssetTypeFilterChange}
                  className={classes.selectPaginationSize}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {assetTypes?.data.map((assetType) => (
                    <MenuItem key={assetType} value={assetType}>
                      <Checkbox checked={selectedAssetTypes.indexOf(assetType) > -1} />
                      <ListItemText primary={assetType} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

            )
          }

          <FormControl variant="filled" className={classes.orderDateStyle}>

            <InputLabel className={classes.paginationSizeLabel}>Date Ordering</InputLabel>
            <Select
              value={sortDirection}
              onChange={handleOrderByChange}
              displayEmpty
              className={classes.selectPaginationSize}
            >
              <MenuItem value="ASC" key="ASC">ASC</MenuItem>
              <MenuItem value="DESC" key="DESC">DESC</MenuItem>
            </Select>
          </FormControl>

        </div>

        {
          assets?.data.map((asset, i) => (
            <AssetAccordion
              asset={asset}
              key={asset?.fileID}
              id={`txtAsset${i}`}
            />
          ))
        }
        <div className={classes.tableIcons}>
          {assets?.pagination && (

            <PaginationComponent
              pageSize={pageSize}
              handleSelectChange={handleSelectChange}
              pagination={assets.pagination}
              handlePageChange={handlePageChange}
            />

          )}
        </div>

        {(!assets || !assets?.data || assets?.data?.length === 0)
          ? <MessageCard message="No assets retrieved" /> : <></>}

      </>
    );
  }

  return (
    <Box>
      <YesNoDialog
        open={yesNoDialogOpen}
        setOpen={setYesNoDialogOpen}
        header="Delete asset"
        body={yesNoDialogText}
        action={() => confirmDeleteAssets()}
        actionName="Yes"
        id="confirmationDeleteAsset"
      />

      <PageBanner
        title="ASSETS"
        id="btnNewAsset"
        buttons={['ADD ASSETS', 'CREATE DEPLOYMENT', 'DELETE ASSETS']}
        buttonIcons={[<UploadLogo />, <UploadLogo />, <DeleteIcon />]}
        buttonDisabled={[false, selectedAssets.length <= 0, selectedAssets.length <= 0]}
        onClicks={[onClickNewAsset, onClickNewDeployment, onClickDeleteAssets]}
        buttonPermission={CREATE_NON_GLOBAL_DEPLOY_PERMISSION}
      />

      <Grid
        className={classes.background}
        container
        spacing={0}
        direction="column"
        alignItems="stretch"
      >

        <div
          className={classes.separate}
        >
          {renderContent()}
        </div>
      </Grid>
    </Box>
  );
}
