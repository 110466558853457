/* eslint-disable no-unused-vars */
/* eslint-disable import/no-named-default */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Container, makeStyles, Grid, Typography,
} from '@material-ui/core';
import {
  Authenticator,
} from '@aws-amplify/ui-react';
import { Auth, I18n } from 'aws-amplify';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import RouterIcon from '@material-ui/icons/Router';
import LinkIcon from '@material-ui/icons/Link';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {

  useLocation,
} from 'react-router-dom';
// import useConfig from '../hooks/useGetConfig';
import LoginButton from '../components/utils/LoginButton';
import { default as logo } from '../assets/portal_logo.svg';
import { target } from '../config/awsExports';
import Maintenance from './Maintenance';
import useRole from '../hooks/useGetRole';
import useConfig from '../hooks/useGetConfig';
import { targetName } from '../utils/portalEnvs';

const useStyles = makeStyles((theme) => ({
  overallContainter: {
    minHeight: '200vh',
    position: 'relative',
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    marginBottom: 20,
    background: 'linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(\'/login_background.png\') no-repeat center center',
    backgroundSize: 'cover',
    maxWidth: '100%',
  },
  maintenanceContainer: {
    background: 'white',
  },
  firstContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100vw',
    height: '100vh',
    background: 'orange',
    backgroundSize: 'cover',
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      height: '100vh',
    },
  },
  secondContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    height: '100vh',
    width: '100vw',
    // make a background with orange and black
    background: 'white', // Using hex codes for orange and black
    backgroundSize: 'cover',
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      height: '100vh',
    },
  },
  thirstContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    height: '100vh',
    width: '100vw',
    background: 'orange',
    backgroundSize: 'cover',
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      height: '100vh',
    },
  },
  nestleContainer: {
    // add a background that is a file in public diretory
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 257,
    borderRadius: '12px',
    marginBottom: '10px',
  },
  authenticator: {
    '& .amplify-button--primary': {
      backgroundColor: theme.palette.primary.button,
    },
    '& .amplify-button--link': {
      color: theme.palette.primary.button,
    },
    '& .amplify-button:hover': {
      backgroundColor: theme.palette.primary.button,
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.button,
    },
    '& .amplify-button:focus': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.button,
      borderColor: theme.palette.primary.main,
    },
    '& .amplify-textfield': {

      color: theme.palette.primary.text,

    },
    '& [data-amplify-router]': {
      // background with opacity
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      borderRadius: 12,
    },
    '& [data-amplify-router-content]': {
      borderRadius: '12px',
      borderStyle: 'none',
    },

  },
  lightTitle: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 300,
    fontStyle: 'normal',
    fontSize: '42px',
    textAlign: 'center',
    color: 'black',
  },
  lightWhiteTitle: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 300,
    fontStyle: 'normal',
    fontSize: '38px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '21px',
    },
    color: 'black',
  },
  headingTitle: {
    fontSize: 16,
    paddingLeft: 4,
    color: 'black',
  },
  lightSubTitle: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ].join(','),
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '1.2em',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1em',
    },
    color: 'black',
    background: 'orange',
  },
  lightSubTitleContainer: {
    padding: 3,
  },
  logo: {
    transform: 'scale(0.8)',
    opacity: 0.9,
  },
  headerTitle: {
    marginBottom: 40,
    marginTop: 40,

  },
  iconSubTitles: {
    color: 'black',
    fontSize: 200,
    marginBottom: 20,
  },
  arrowIcon: {
    color: 'black',
    backgroundColor: '#00000080',
    fontSize: 40,
    borderRadius: 50,
  },
}));

const StyledAuthenticator = () => {
  I18n.putVocabulariesForLanguage('en', {
    'Sign In': 'Log in with Amazon',
  });
  const classes = useStyles();
  const formFields = {
    signIn: {
      username: {
        placeholder: 'Username',
        isRequired: true,
        labelHidden: true,
      },
      password: {
        placeholder: 'Password',
        isRequired: true,
        labelHidden: true,
      },
    },
  };

  // eslint-disable-next-line max-len
  return <Authenticator hideSignUp formFields={formFields} className={classes.authenticator} />;
};

// Define the animation variants
const arrowVariants = {
  animate: {
    y: [0, 10],
    transition: {
      y: {
        yoyo: Infinity, // Make the animation loop forever
        duration: 0.6, // Time in seconds for one cycle
        ease: 'easeInOut',
      },
    },
  },
};

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 1.5,
      when: 'beforeChildren',
      staggerChildren: 0.5,
    },
  },
};

const itemVariants = {
  hidden: { x: -10, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 200,
      duration: 3,
    },
  },
};

export default function Login() {
  const classes = useStyles();
  const location = useLocation();
  const [isInMaintenance, setIsInMaintenance] = useState(false);
  const routeContainsPortal = window.location.href.includes('portal');
  const routeContainsAdmin = window.location.href.includes('admin');
  const newUrlPortal = target === 'STAG' ? `https://portal.stag.niotp.com/#${location.pathname}` : `https://portal.niotp.com/#${location.pathname}`;
  const { role } = useRole();
  const { configData } = useConfig();

  useEffect(() => {
    // if target is not DEv and contains portal redirect to the new url Portal
    if (target === 'DEVS' && (!routeContainsPortal)) {
      window.location.href = newUrlPortal;
    }
  }, [routeContainsPortal, newUrlPortal]);

  useEffect(() => {
    setIsInMaintenance(configData?.maintenance === 'true');
  }, [configData]);

  const titleName = `Consumer IoT Portal (${targetName[target]})`;

  const { scrollYProgress } = useViewportScroll();
  const opacityContainer1 = useTransform(scrollYProgress, [0.1, 0.3], [0, 1]);
  const opacityContainer2 = useTransform(scrollYProgress, [0.5, 0.7], [0, 1]);
  const opacityContainer3 = useTransform(scrollYProgress, [0.7, 1], [0, 1]);

  const handleLogin = () => {
    Auth.federatedSignIn({ provider: 'NestleSSO' })
      .then(() => {
        console.log('User signed in with SSO');
      })
      .catch(console.error);
  };

  return (

    <div className={classes.overallContainter}>
      {/* Main Container */}
      {isInMaintenance && (!routeContainsAdmin || (role && role !== 'rootAccess'))
        ? <Maintenance className={classes.mainContainer} /> : (
          <Container className={classes.maintenanceContainer}>

            <Grid display="flex-start" alignItems="center" direction="row" container className={classes.mainTitlePadding}>
              <Grid item>
                <img src={logo} alt="Logo" className={classes.logo} />
              </Grid>
              <Grid item>
                <Typography className={classes.headingTitle} variant="h2">
                  {titleName}
                </Typography>
              </Grid>

            </Grid>

            <Container className={classes.mainContainer} fixed>

              <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                className="page-container"
              >
                <Grid container display="flex" direction="column" justifyContent="center" alignContent="center" alignItems="center" className={classes.headerTitle}>

                  <Typography className={classes.lightTitle}>
                    Welcome to IoT Portal
                  </Typography>

                  <motion.div variants={itemVariants}>
                    <div className={classes.lightSubTitleContainer}>
                      <Typography className={classes.lightSubTitle}>
                        your gateway  to the Consumer IoT Platform
                      </Typography>
                    </div>

                  </motion.div>

                </Grid>
              </motion.div>
              <Grid container alignContent="center" display="flex" direction="column" alignItems="center" justifyContent="center">
                {target !== 'DEV' && (
                  <Grid item>
                    <LoginButton style={{ marginBottom: 20 }} type="button" onClick={handleLogin}>
                      Login with Nestlé SSO
                    </LoginButton>
                  </Grid>
                )}

                <Grid item>
                  <StyledAuthenticator />
                </Grid>
                <Grid item>
                  <motion.div
                    variants={arrowVariants}
                    initial="animate"
                    animate="animate"
                    style={{
                      fontSize: '24px', // Set the size of the arrow
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '50px', // Height of the container for the arrow
                      cursor: 'pointer',
                      marginTop: 50,
                      marginBottom: 20,
                    }}
                  >
                    <ArrowDownwardIcon className={classes.arrowIcon} />
                  </motion.div>
                </Grid>
              </Grid>
            </Container>

          </Container>
        )}

      {/* 1st Container */}
      <Container className={classes.firstContainer}>
        <motion.div style={{ opacity: opacityContainer1 }}>
          <ImportantDevicesIcon className={classes.iconSubTitles} />
        </motion.div>

        <motion.div style={{ opacity: opacityContainer1 }}>
          <Typography className={classes.lightWhiteTitle}>
            Remote device monitoring including
          </Typography>

        </motion.div>
        <motion.div style={{ opacity: opacityContainer1 }}>
          <Typography className={classes.lightWhiteTitle}>
            connectivity dashboard,
          </Typography>

        </motion.div>
        <motion.div style={{ opacity: opacityContainer1 }}>
          <Typography className={classes.lightWhiteTitle}>
            consumption telemetry
          </Typography>

        </motion.div>
        <motion.div style={{ opacity: opacityContainer1 }}>
          <Typography className={classes.lightWhiteTitle}>
            and FOTA events
          </Typography>

        </motion.div>

      </Container>

      {/* 2nd Container */}
      <Container className={classes.secondContainer}>
        <motion.div style={{ opacity: opacityContainer2 }}>
          <RouterIcon className={classes.iconSubTitles} />
        </motion.div>

        <motion.div style={{ opacity: opacityContainer2 }}>
          <Typography className={classes.lightWhiteTitle}>
            Convenient firmware deployment and monitoring
          </Typography>
        </motion.div>

      </Container>

      {/* 3st Container */}
      <Container className={classes.thirstContainer}>
        <motion.div style={{ opacity: opacityContainer3 }}>
          <LinkIcon className={classes.iconSubTitles} />
        </motion.div>

        <motion.div style={{ opacity: opacityContainer3 }}>
          <Typography className={classes.lightWhiteTitle}>
            Support links to Service Now tickets
          </Typography>

        </motion.div>

      </Container>
    </div>
  );
}
