import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
  CircularProgress,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  InputLabel,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useQuery } from '@tanstack/react-query';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import GraphqlService from '../../service/graphqlService';
import { useProject } from '../../config/ProjectContext';
import JsonPopup from '../../components/JsonPopup';

const useStyles = makeStyles((theme) => ({
  dialogTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#F3916A',
    color: theme.palette.primary.contrastText,
  },
  dialogTitleText: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1.5rem',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  detailTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleDivide: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(2),
  },
  detailIcon: {
    padding: 0,
  },
}));

function BundleDetailPopup({ releaseID }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const { project } = useProject();

  const { data: bundleDetail, isLoading: loading } = useQuery(
    ['bundleDetail', releaseID],
    () => GraphqlService.getBundle({ projectId: project?.code, releaseID }),
    {
      enabled: open && !!project?.code,
    },
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openAsset = (fileId) => {
    const url = `${window.location.origin}/#/assetDetail/${fileId}`;
    window.open(url, '_blank');
  };

  const getVersionFromString = (stringValue) => {
    const versionMatch = stringValue?.match(/Version=([^,}]*)/);
    if (versionMatch && versionMatch[1]) {
      return versionMatch[1].trim();
    }
    return undefined;
  };

  return (
    <div>
      {/* Button to open the dialog */}
      <IconButton onClick={handleClickOpen} color="primary">
        <VisibilityIcon />
      </IconButton>

      {/* Dialog for showing bundle details */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="bundle-detail-dialog-title"
        maxWidth="md"
        fullWidth
      >

        <div className={classes.dialogTitleContainer}>
          <Typography className={classes.dialogTitleText}>
            {bundleDetail?.title
              ? `Bundle detail: ${bundleDetail.title} - ${bundleDetail.dateTime?.toString('dd/MM/yyyy HH:mm:ss')}`
              : 'Bundle detail'}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent dividers>
          {loading ? (
            <CircularProgress />
          ) : (
            bundleDetail && (
              <>

                <Divider className={classes.titleDivide} />
                {/* Render the detail of the selected bundle */}
                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <TableContainer component={Paper} className={classes.table}>
                      <Table aria-label="selected assets table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Version</TableCell>
                            <TableCell>Creation Date</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {bundleDetail.fileIDs?.map((asset) => (
                            <TableRow key={asset.fileID}>
                              <TableCell>{asset.type}</TableCell>
                              <TableCell>{getVersionFromString(asset.assetDescription)}</TableCell>
                              <TableCell>{asset.dateTime?.toString('dd/MM/yyyy HH:mm:ss')}</TableCell>
                              <TableCell>
                                <IconButton className={classes.detailIcon} onClick={() => openAsset(asset.fileID)}>
                                  <ExitToAppIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={7}>
                    <InputLabel htmlFor="description"> Description</InputLabel>
                    <textarea
                      id="description"
                      disabled
                      value={bundleDetail.releaseDescription?.description}
                      rows="4"
                      cols="50"
                      style={{ width: '100%', padding: '8px', marginTop: '8px' }}
                    />
                  </Grid>
                </Grid>
              </>
            )
          )}
        </DialogContent>
        <DialogActions>
          <JsonPopup
            title={`Bundle ${typeof bundleDetail?.title === 'string' ? bundleDetail?.title : 'Unknown'}`}
            jsonObject={typeof bundleDetail === 'object' && bundleDetail !== null ? bundleDetail : {}}
          />
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

BundleDetailPopup.propTypes = {
  releaseID: PropTypes.string.isRequired,
};

export default BundleDetailPopup;
