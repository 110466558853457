/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box, Grid, makeStyles, AppBar, Tab, Tabs, Typography,
} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  React, useContext, useEffect, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useProject } from '../../config/ProjectContext';
import GraphqlService from '../../service/graphqlService';
import PageBanner from '../../components/utils/PageBanner';
import SimpleAlert from '../../components/utils/SimpleAlert';
import ProgressDialog from '../../components/utils/ProgressDialog';
import Filters from '../../components/utils/Filters';
import BrandObjectTable from './MachineTelemetry/BrandObjectTable';

import {
  validate, calculateEndDateTimeIso, calculateStartDateTimeIso,
} from '../../utils/dateUtils';

const useStyles = makeStyles((theme) => ({
  formField: {
    width: '100%',
    '& ::placeholder': {
      paddingLeft: 8,
      paddingTop: 8,
    },
  },
  formPlaceholder: {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'blue',
    },
  },
  inputDescriptionLabel: {
    font: theme.typography.h4.font,
    color: theme.palette.primary.text,
  },
  detailsTitle: {
    paddingRight: '5px',
    fontFamily: theme.typography.h5.fontFamily,
    lineHeight: theme.typography.h5.lineHeight,
    letterSpacing: theme.typography.h5.letterSpacing,
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.primary.subMain,
  },
  accordion: {
    borderRadius: '12px !important',
  },
  details: {
    paddingLeft: 24,
    paddingRight: 24,
  },
}));

export default function DeviceInfo({ openSnackBar }) {
  const MAX_DAYS_BETWEEN_DATES = 90;
  const paramsDeploymentEventsHistory = {};
  // STATE________________________________
  const { project } = useProject();

  // grid
  const [totalCount, setTotalCount] = useState(0);
  const [reducedPage, setReducedPage] = useState(0);
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const [rowsPerPageConsumption] = useState(10);
  const [reducedResponse, setReducedResponse] = useState(null);

  //  alert data
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertText, setAlertText] = useState('');

  //  state values
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);

  // errors
  const [dateStartError, setDateStartError] = useState(false);
  const [dateEndError, setDateEndError] = useState(false);
  const [greaterSmallerDateError, setGreaterSmallerDateError] = useState(false);
  const [moreThanMaxDaysDateError, setMoreThanMaxDaysDateError] = useState(false);
  const [deviceSerialError, setDeviceSerialError] = useState(false);

  // data
  const [selectedFilters, setSelectedFilters] = useState('');
  const [deviceSerial, setDeviceSerial] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(moment().add(-29, 'days'));
  const [selectedStartTime, setSelectedStartTime] = useState(moment().add(-29, 'days').format('HH:mm'));
  const [selectedEndDate, setSelectedEndDate] = useState(moment());
  const [selectedEndTime, setSelectedEndTime] = useState(moment().format('HH:mm'));

  // progress dialog
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);

  function openAlert(header, text) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
  }

  // FORM_________________________________
  const {
    handleSubmit, register, formState: { errors },
  } = useForm();

  // functions to obtain the data to show
  async function searchObject(myProject, filters, startDate, endDate, paginationToken) {
    let response = [];
    response = await GraphqlService.getLifeCycleObject(
      project.code, `machineassetsdetail/${project.code}/${deviceSerial}`, JSON.stringify(paramsDeploymentEventsHistory), filters, startDate, endDate, MAX_DAYS_BETWEEN_DATES, paginationToken,
    );
    // eslint-disable-next-line no-plusplus
    if (response) {
      setPaginationTokens((prev) => {
        if (response?.pagination && response?.pagination.paginationContinuationToken && !prev.some((token) => token === response.pagination.paginationContinuationToken)) {
          return [...prev, response.pagination.paginationContinuationToken];
        }
        return prev;
      });
      const generalData = JSON.parse(response.data);
      return generalData;
    }
    return [];
  }

  async function search(myProject, filters, startDate, endDate, startTime, endTime, newPage, newValue) {
    try {
      setSubmitting(true);
      setProgressDialogOpen(true);
      setLoading(true);
      let creationResponse = [];

      setReducedPage(newPage || 0);
      creationResponse = await searchObject(myProject, filters, calculateStartDateTimeIso(startDate, startTime), calculateEndDateTimeIso(endDate, endTime), paginationTokens[newPage] ? paginationTokens[newPage] : undefined);
      setPaginationTokens(['']);
      setReducedResponse(creationResponse);
      setMoreThanMaxDaysDateError(false);

      setSubmitting(false);
      setProgressDialogOpen(false);
      setLoading(false);
      return creationResponse;
    } catch (error) {
      console.log('Error!!!', error);
      setProgressDialogOpen(false);
      setSubmitting(false);
      setLoading(false);
      openAlert('Error', error.message);
      return null;
    }
  }

  const onSubmit = (data) => {
    setDeviceSerialError(false);
    if (deviceSerial === '') {
      setDeviceSerialError(true);
      return;
    }
    if (selectedStartDate === null || selectedEndDate === null || selectedStartTime === null || selectedEndTime === null) {
      return;
    }
    if (validate(selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, MAX_DAYS_BETWEEN_DATES, setDateStartError, setDateEndError, setSelectedStartDate, setSelectedStartTime, setSelectedEndDate, setSelectedEndTime, setGreaterSmallerDateError, setMoreThanMaxDaysDateError)) {
      search(
        project, selectedFilters, selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime,
      );
    }
  };

  // WEBPAGE______________________________
  const classes = useStyles();

  useEffect(() => {
    setPaginationTokens(['']);
  }, [selectedFilters, selectedStartDate, selectedEndDate]);

  return (
    <Box spacing={0}>
      <ProgressDialog open={progressDialogOpen} setOpen={setProgressDialogOpen} header="Retrieving statistics, please wait" />
      <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header={alertHeader} body={alertText} />
      <PageBanner title="DEPLOYMENT EVENTS HISTORY" />
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ padding: 0 }}
      >
        <Filters
          id="DeploymentEventsHistory"
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          submitting={submitting}
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedStartTime={selectedStartTime}
          setSelectedStartTime={setSelectedStartTime}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          selectedEndTime={selectedEndTime}
          setSelectedEndTime={setSelectedEndTime}
          greaterSmallerDateError={greaterSmallerDateError}
          setGreaterSmallerDateError={setGreaterSmallerDateError}
          moreThanMaxDaysDateError={moreThanMaxDaysDateError}
          setMoreThanMaxDaysDateError={setMoreThanMaxDaysDateError}
          datesOptional={false}
          filtersVisible={false}
          deviceSerialVisible
          deviceSerial={deviceSerial}
          setDeviceSerial={setDeviceSerial}
          deviceSerialError={deviceSerialError}
          maxDaysBetweenDates={MAX_DAYS_BETWEEN_DATES}
          subtitle="Enter Device Serial to view asset deployment events"
        />
      </form>
      <Grid item xs={12}>
        <BrandObjectTable
          search={(newPage) => search(project, selectedFilters, selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, newPage)}
          dataList={reducedResponse}
          totalCount={totalCount}
          filters={selectedFilters}
          loading={loading}
          dateIni={calculateStartDateTimeIso(selectedStartDate, selectedStartTime)}
          dateEnd={calculateEndDateTimeIso(selectedEndDate, selectedEndTime)}
          rangeDates={MAX_DAYS_BETWEEN_DATES}
          page={reducedPage}
          setPage={setReducedPage}
          rowsPerPage={rowsPerPageConsumption}
          paginationTokens={paginationTokens}
          object={`machineassetsdetail/${project.code}/${deviceSerial}`}
          params={JSON.stringify(paramsDeploymentEventsHistory)}
          id="DeploymentEventsHistory"
          pagination="token"
          columnInclude="validData"
        />
      </Grid>
    </Box>
  );
}
DeviceInfo.propTypes = {
  openSnackBar: PropTypes.func.isRequired,
};
